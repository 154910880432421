'use client';

import { Button } from '@canvaza/serval-ui';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import InvitedWorkspace from '@/app/(protected)/(user)/workspace/InvitedWorkspace';
import TextInput from '@/common/component/forms/TextInput';
import LabeledHorizontalLine from '@/common/component/line/LabeledHorizontalLine';
import {
  FORGOT_PASSWORD,
  REGISTER,
  ROOT,
} from '@/shared/config/route-links.config';
import { useAuth } from '@/shared/hooks/useAuth';

import LoginWithGoogleOrSSO from './LoginWithGoogleOrSSO';

const schema = yup.object().shape({
  email: yup.string().email().required().label('Email Address'),
  password: yup.string().required().label('Password'),
});

export default function LoginForm() {
  const [show, setShow] = React.useState(false);

  const { loginWithEmailAndPassword, withPasswordLoading, error, setError } =
    useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data: FieldValues) => {
    await loginWithEmailAndPassword(data.email, data.password);
  };

  return (
    <div className="mt-32 flex w-full items-center justify-center xs:mt-0">
      <div className="flex w-full items-center justify-center">
        <div className="flex w-full flex-col gap-5">
          <h2 className="flex w-full justify-start text-center text-base text-dark-700 xs:text-xl">
            <span className="font-normal"> Sign in to</span>
            <Link
              href={ROOT.url}
              onClick={() => setError('')}
              className="ml-2 font-bold"
            >
              CANVAZA
            </Link>
          </h2>

          <div className="flex w-full flex-col items-center justify-center gap-2">
            <div className="flex w-full items-start justify-start">
              <InvitedWorkspace />
            </div>
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-2 xs:gap-3">
                <div className="flex flex-col gap-4 xs:gap-6">
                  <TextInput
                    id="email"
                    type="email"
                    placeholder="Enter Email Address"
                    fullWidth
                    {...register('email')}
                    error={!!errors.email?.message}
                    description={errors.email?.message?.toString() || ''}
                  />

                  <TextInput
                    id="password"
                    type={show ? 'text' : 'password'}
                    placeholder="Enter Password"
                    fullWidth
                    {...register('password')}
                    error={!!errors.password?.message}
                    description={errors.password?.message?.toString() || ''}
                    inputAdornment={{
                      endAdornment: (
                        <>
                          <div
                            role="button"
                            onClick={() => setShow(!show)}
                            className={clsx(
                              'cursor-pointer text-dark-700 dark:text-dark-200'
                            )}
                          >
                            {show ? (
                              <EyeIcon className="h-6 w-6" />
                            ) : (
                              <EyeSlashIcon className="h-6 w-6" />
                            )}
                          </div>
                        </>
                      ),
                    }}
                  />
                </div>
                <p className="text-justify text-error dark:text-error-light">
                  {error}
                </p>

                <div className="mt-2 text-right text-sm text-primary underline">
                  <Link href={FORGOT_PASSWORD.url}>Forgot Password?</Link>
                </div>
                <Button
                  isLoading={withPasswordLoading}
                  disabled={withPasswordLoading}
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                  className="bg-primary"
                >
                  Login
                </Button>
              </div>
            </form>

            <LabeledHorizontalLine label="Or" lineColor />

            <LoginWithGoogleOrSSO />

            <div className="flex w-full justify-start">
              <span className="mt-6 text-base xs:text-lg">
                New to CANVAZA?
                <Link
                  href={REGISTER.url}
                  onClick={() => setError('')}
                  className="ml-2 font-semibold text-primary underline dark:text-primary-light"
                >
                  Sign up for free
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
