import { Button, GoogleIcon } from '@canvaza/serval-ui';
import React from 'react';

import { SSO } from '@/shared/config/route-links.config';
import { useAuth } from '@/shared/hooks/useAuth';
import { CloudSSOIcon } from '@/shared/utils/svg';

export default function LoginWithGoogleOrSSO() {
  const { loginWithGoogle } = useAuth();

  const { withGoogleLoading } = useAuth();

  const handleSSORedirect = () => {
    window.location.pathname = SSO.url;
  };
  return (
    <div className="flex w-full flex-col gap-3">
      <Button
        isLoading={withGoogleLoading}
        disabled={withGoogleLoading}
        onClick={loginWithGoogle}
        variant="outlined"
        startIcon={<GoogleIcon className="h-6 w-6" />}
        fullWidth
        size="large"
      >
        Sign In with Google
      </Button>

      <Button
        onClick={handleSSORedirect}
        variant="outlined"
        size="large"
        startIcon={
          <CloudSSOIcon className="h-6 w-6 fill-secondary p-0 dark:fill-secondary-light" />
        }
        fullWidth
      >
        Sign In with SSO
      </Button>
    </div>
  );
}
