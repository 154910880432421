import clsx from 'clsx';
import React from 'react';

type LabeledHorizontalLineProps = {
  label: string;
  lineColor?: boolean;
};
export default function LabeledHorizontalLine({
  label,
  lineColor,
}: LabeledHorizontalLineProps) {
  return (
    <div
      className={clsx(
        'my-4 flex w-full items-center before:flex-1 before:border-t before:border-dark-300',
        'before:mt-0.5 after:flex-1 after:border-t after:border-dark-300 dark:before:border-dark-600',
        'after:mt-0.5 dark:after:border-dark-600',
        {
          'before:border-primary-lighter after:border-primary-lighter ':
            lineColor,
        }
      )}
    >
      <p className="mx-4 mb-0 text-center font-semibold">{label}</p>
    </div>
  );
}
